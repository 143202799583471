import projectIcon1 from "assets/images/project/ninga-image.png";
import projectIcon2 from "assets/images/project/ninga-2.png";
import projectIcon3 from "assets/images/project/ninga-3.png";
// import projectIcon4 from "assets/images/project/ninga-4.png";

import coinIcon1 from "assets/images/project/icon-2.png";
import baseIcon from "assets/images/project/base.png"
import coinIcon2 from "assets/images/project/icon-1.png";
import coinIcon3 from "assets/images/project/icon-3.png";
// import coinIcon4 from "assets/images/project/icon-4.png";
const data = [
  {
    projectIcon: projectIcon1,
    title: "DRGN Token Seed Sale",
    price: "11.00 USDT",
    totalRise: "1,200.00 ( 100% )",
    allocation: "From 1,000.00 USDT/USDC",
    targetedRise: "1,200 GAFI Tokens",
    saleEnd: 1492560,
    coinIcon: baseIcon,
    progress: "100%",
  },
  // {
  //   projectIcon: projectIcon2,
  //   title: "Solanium IDO",
  //   price: "0.37 USDC",
  //   totalRise: "50,0000 SLIM ( 100% )",
  //   allocation: "From 900 USDC",
  //   targetedRise: "51,000.00 SLIM",
  //   saleEnd: 1132560,
  //   coinIcon: coinIcon2,
  //   progress: "100%",
  // },
  // {
  //   projectIcon: projectIcon3,
  //   title: "BscPad",
  //   price: "0.21 USDT",
  //   totalRise: "75,000 BSCPAD ( 100% )",
  //   allocation: "From 1,000 USDT",
  //   targetedRise: "75,000 BSCPAD",
  //   saleEnd: 113560,
  //   coinIcon: coinIcon3,
  //   progress: "100%",
  // },
  // {
  //   projectIcon: projectIcon4,
  //   title: "Thetan Arena",
  //   price: "1.30 BUSD",
  //   totalRise: "22,987.70 BUSD ( 43% )",
  //   allocation: "500 BUSD Max",
  //   targetedRise: "230,000 BUSD",
  //   saleEnd: 1013560,
  //   coinIcon: coinIcon4,
  //   progress: "36%",
  // },
];

export default data;
