const data = [
  {
    id: "1W6WV",
    title: "Home",
    url: "#",
    // subMenus: [
    //   {
    //     id: "TU4X2",
    //     title: "Home 1",
    //     url: "/",
    //   },
    //   {
    //     id: "WTY5W",
    //     title: "Home 2",
    //     url: "/home-two",
    //   },
    //   {
    //     id: "365V6",
    //     title: "Home 3",
    //     url: "/home-three",
    //   },
    // ],
  },
  {
    id: "2ZYYU",
    title: "Projects",
    url: "#",
    // subMenus: [
    //   {
    //     id: "0300T",
    //     title: "Project Classic",
    //     url: "/projects-classic-1",
    //   },
    //   {
    //     id: "4XZ00",
    //     title: "Project List",
    //     url: "/projects-list",
    //   },
    //   {
    //     id: "43X1V",
    //     title: "Project Grid",
    //     url: "/projects-grid",
    //   },
    //   {
    //     id: "4IX1V",
    //     title: "Projects Calendar",
    //     url: "/projects-calendar",
    //   },
    //   {
    //     id: "VWXWY",
    //     title: "Project Details",
    //     url: "/projects-details-1",
    //   },
    // ],
  },
  {
    id: "435U5",
    title: "Staking",
    url: "#",
    // subMenus: [
    //   {
    //     id: "U662W",
    //     title: "Staking 1",
    //     url: "/staking-1",
    //   },
    //   {
    //     id: "VT0WW",
    //     title: "Staking 2",
    //     url: "/staking-2",
    //   },
    //   {
    //     id: "Y43TW",
    //     title: "Staking 3",
    //     url: "/staking-3",
    //   },
    //   {
    //     id: "Y1Y2T",
    //     title: "Staking 4",
    //     url: "/staking-4",
    //   },
    //   {
    //     id: "TTV2U",
    //     title: "Staking 5",
    //     url: "/staking-5",
    //   },
    // ],
  },
  {
    id: "ZZUVV",
    title: "Pages",
    url: "#",
    // subMenus: [
    //   {
    //     id: "5Z4XW",
    //     title: "KYC Process",
    //     url: "#",
    //     subMenuChilds: [
    //       {
    //         id: "V6X1W",
    //         title: "KYC Step 1",
    //         url: "/kyc-1",
    //       },
    //       {
    //         id: "1WXX4",
    //         title: "KYC Step 2",
    //         url: "/kyc-2",
    //       },
    //       {
    //         id: "V6YVU",
    //         title: "KYC Step 3",
    //         url: "/kyc-3",
    //       },
    //     ],
    //   },
    //   {
    //     id: "3YY6V",
    //     title: "Auth Page",
    //     url: "#",
    //     subMenuChilds: [
    //       {
    //         id: "X6ZU4",
    //         title: "Sign In",
    //         url: "/login",
    //       },
    //       {
    //         id: "YVWVW",
    //         title: "Sign Up",
    //         url: "/register",
    //       },
    //       {
    //         id: "2ZYW6",
    //         title: "Forget Password",
    //         url: "/forget-password",
    //       },
    //     ],
    //   },
    //   {
    //     id: "39Y6V",
    //     title: "Farming",
    //     url: "/farming",
    //   },
    //   {
    //     id: "4XY6V",
    //     title: "Leader Board",
    //     url: "/leaderboard",
    //   },
    //   {
    //     id: "4XP60",
    //     title: "IGO Ranking",
    //     url: "/igo-ranking",
    //   },
    //   {
    //     id: "4XL90",
    //     title: "IGO Apply",
    //     url: "/igo-apply",
    //   },
    //   {
    //     id: "PMQ60",
    //     title: "Roadmap Details",
    //     url: "/roadmap-details",
    //   },
    //   {
    //     id: "39X8V",
    //     title: "Team Details",
    //     url: "/team-details",
    //   },
    //   {
    //     id: "LTM20",
    //     title: "Contact",
    //     url: "/contact",
    //   },
    // ],
  },
];

export default data;
