// import facebook from "assets/images/icons/facebook.svg";
// import discord from "assets/images/icons/discord.svg";
// import insta from "assets/images/icons/insta.svg";
import linkedin from "assets/images/icons/linkedin.svg";
// import medium from "assets/images/icons/medium.svg";
import telegram from "assets/images/icons/telegram.svg";
import twitter from "assets/images/icons/twitter.svg";
const data = [
  // {
  //   icon: facebook,
  //   url: "#",
  // },
  // {
  //   icon: discord,
  //   url: "#",
  // },
  // {
  //   icon: insta,
  //   url: "#",
  // },
  {
    icon: linkedin,
    url: "https://www.linkedin.com/company/dragonpad/",
  },
  // {
  //   icon: medium,
  //   url: "#",
  // },
  {
    icon: telegram,
    url: "https://t.me/dragonpad",
  },
  {
    icon: twitter,
    url: "https://twitter.com/DragonPadAI",
  },
];

export default data;
