import projectThumb1 from "assets/images/project/privius-image.png";
import projectThumb2 from "assets/images/project/privius-image2.png";
import projectThumb3 from "assets/images/project/privius-image3.png";
import projectThumb4 from "assets/images/project/privius-image4.png";
import projectThumb5 from "assets/images/project/privius-image5.png";

import coinIcon1 from "assets/images/project/project-single-image.png";
import coinIcon2 from "assets/images/project/project-single-image2.png";
import coinIcon3 from "assets/images/project/project-single-image3.png";
import coinIcon4 from "assets/images/project/project-single-image4.png";
import coinIcon5 from "assets/images/project/project-single-image5.png";
import loeIcon from "assets/images/project/legendsofelysiumIcon.png";

const data = [
  {
    thumb: loeIcon,
    title: "Legends of Elysium",
    price: "0.05",
    ATH: "x8.21",
    launchedDate: "03/29/2024",
    totalRised: "200,000",
    progress: "100%",
    coinIcon: coinIcon2,
  },
  {
    thumb: projectThumb2,
    title: "MOONBOOTS DAO",
    price: "0.125",
    launchedDate: "05/07/2024",
    totalRised: "200,000",
    progress: "100%",
    coinIcon: coinIcon2,
  },
  {
    thumb: projectThumb3,
    title: "Meta World",
    price: "0.33",
    launchedDate: "05",
    totalRised: "99,0000",
    progress: "98%",
    coinIcon: coinIcon3,
  },
  {
    thumb: projectThumb4,
    title: "Fisrt Survivor",
    price: "0.89",
    launchedDate: "12",
    totalRised: "82,6000",
    progress: "100%",
    coinIcon: coinIcon4,
  },
  {
    thumb: projectThumb5,
    title: "Cryowar Two",
    price: "0.45",
    launchedDate: "14",
    totalRised: "38,8000",
    progress: "86%",
    coinIcon: coinIcon5,
  },
];

export default data;
