import { FaTelegramPlane, FaTwitter, FaDiscord,  FaGlobe} from "react-icons/fa"
import { BsMedium } from "react-icons/bs";

import projectThumb1 from "assets/images/project/project-image.png";
import projectThumb2 from "assets/images/project/project-image2.png";
import projectThumb3 from "assets/images/project/project-image3.png";
import projectThumb4 from "assets/images/project/project-image4.png";
import projectThumb5 from "assets/images/project/project-image5.png";
import projectThumb6 from "assets/images/project/project-image6.png";

import coinIcon1 from "assets/images/project/project-single-image.png";
import coinIcon2 from "assets/images/project/project-single-image2.png";
import coinIcon3 from "assets/images/project/project-single-image3.png";
import coinIcon4 from "assets/images/project/project-single-image4.png";
import coinIcon5 from "assets/images/project/project-single-image5.png";
import coinIcon6 from "assets/images/project/project-single-image6.png";
import solanaProjectIcon from "assets/images/project/SolanaBiggerLogo.png";
import baseProjectIcon from "assets/images/project/baseProjectIcon.png";


const data = [
    {
        thumb: projectThumb1,
        title: "SKIBIDISOL",
        price: "0.0003 SOL",
        saleEnd: "TBA",
        coinIcon: solanaProjectIcon,
        projectDetails: [
            {
                 title: "Min allocation",
                 text: "TBA"
            },
            {
                 title: "Max allocation",
                 text: "TBA"
            },
            {
                 title: "Targeted raise",
                 text: "TBA"
            },
            {
                 title: "Access type",
                 text: "Public"
            }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb2,
        title: "MILLIONMEMETOKEN",
        price: "TBA",
        saleEnd: "TBA",
        coinIcon: solanaProjectIcon,
        projectDetails: [
            {
                 title: "Min allocation",
                 text: "TBA"
            },
            {
                 title: "Max allocation",
                 text: "TBA"
            },
            {
                 title: "Targeted raise",
                 text: "TBA"
            },
            {
                 title: "Access type",
                 text: "Public"
            }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb3,
        title: "YTKA TON",
        price: "TBA",
        saleEnd: "TBA",
        coinIcon: solanaProjectIcon,
        projectDetails: [
            {
                 title: "Min allocation",
                 text: "TBA"
            },
            {
                 title: "Max allocation",
                 text: "TBA"
            },
            {
                 title: "Targeted raise",
                 text: "TBA"
            },
            {
                 title: "Access type",
                 text: "Public"
            }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb4,
        title: "MORKUS BRONLI",
        price: "TBA",
        saleEnd: "TBA",
        coinIcon: baseProjectIcon,
        projectDetails: [
            {
                 title: "Min allocation",
                 text: "TBA"
            },
            {
                 title: "Max allocation",
                 text: "TBA"
            },
            {
                 title: "Targeted raise",
                 text: "TBA"
            },
            {
                 title: "Access type",
                 text: "Public"
            }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb5,
        title: "DERELIKT RWA",
        price: "TBA",
        saleEnd: "TBA",
        coinIcon: baseProjectIcon,
        projectDetails: [
            {
                 title: "Min allocation",
                 text: "TBA"
            },
            {
                 title: "Max allocation",
                 text: "TBA"
            },
            {
                 title: "Targeted raise",
                 text: "TBA"
            },
            {
                 title: "Access type",
                 text: "Public"
            }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
    {
        thumb: projectThumb6,
        title: "AI DRAGONS NFT",
        price: "TBA",
        saleEnd: "TBA",
        coinIcon: baseProjectIcon,
        projectDetails: [
            {
                 title: "Min allocation",
                 text: "TBA"
            },
            {
                 title: "Max allocation",
                 text: "TBA"
            },
            {
                 title: "Targeted raise",
                 text: "TBA"
            },
            {
                 title: "Access type",
                 text: "Public"
            }
        ],
        socialLinks: [
            {
                icon: <FaTelegramPlane />,
                url: "#"
            },
            {
                icon: <FaTwitter />,
                url: "#"
            },
            {
                icon: <FaDiscord />,
                url: "#"
            },
            {
                icon: <BsMedium />,
                url: "#"
            },
            {
                icon: <FaGlobe />,
                url: "#"
            },
        ]
    },
]

export default data;