const data = [
  {
    title: "Team",
    value: "10",
  },
  {
    title: "Rewards",
    value: "10.00",
  },
  {
    title: "Advisors",
    value: "10.00",
  },
  {
    title: "Liquidity",
    value: "15.00",
  },
  {
    title: "Ecosystem",
    value: "10.00",
  },
  {
    title: "Seed Sale",
    value: "20.00",
  },
  {
    title: "Private Sale",
    value: "15.00",
  },
  {
    title: "Public Sale",
    value: "10.00",
  },
];

export default data;
