const data = [
  {
    title: "Expected IDOs 2024",
    value: "150",
  },
  {
    title: "Unique Participants",
    value: "12250",
  },
  {
    title: "Target Raise",
    value: "4.3",
  },
  {
    title: "Initial Market Cap",
    value: "13.5",
  },
];

export default data;
